import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { addMarketingData, getMarketingData, deleteMarketingData } from '@/application/services/marketing-data'
import { useRouter } from 'vue-router'
import { getCurrentSenderNames } from '@/application/services/sender-name'
import { useModal } from '@/application/composables/modal'
import { getLocalizedServerError, getLocalizedOrValue } from '@/application/utils/localization'

export function useMarketingDataCreate() {
  const i18n = useI18n()
  const router = useRouter()
  const { openModal } = useModal()
  const route = router.currentRoute.value
  const senderName = ref(null)
  const marketingItems = ref(null)
  const collapsedForm = ref(true)
  const btnDisabled = ref(false)

  const refreshMarketingData = () => {
    getMarketingData(route.params.id).then(({ data }) => {
      marketingItems.value = data.values
      if (data.types) {
        formSchema.value.type.children = computed(() => data.types.map((type) => ({
          tag: 'option',
          text: getLocalizedOrValue(i18n, 'forms.marketingData.types.' + type, type),
          value: type,
        })))
      }
    })
  }

  const submit = async(formData, { resetForm }) => {
    btnDisabled.value = true
    try {
      await addMarketingData(route.params.id, formData)
      openModal(i18n.t('success'), i18n.t('forms.marketingData.successMessage'))
      resetForm()
      collapsedForm.value = true
      refreshMarketingData()
    } catch (exception) {
      openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.marketingData.errors.', exception.response))
    }
    btnDisabled.value = false
  }
  getCurrentSenderNames().then(({ data }) => {
    senderName.value = data.sender_names.find((senderName) => route.params.id === senderName.sender_name_id)
  })
  refreshMarketingData()

  const formSchema = ref({
    type: {
      label: computed(() => i18n.t('type')),
      name: 'type',
      as: 'select',
      type: 'text',
      rules: 'required',
      cols: 'col-12 col-md-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      children: [],
    },
    value: {
      label: computed(() => i18n.t('value')),
      name: 'value',
      type: 'text',
      as: 'input',
      rules: 'required',
      validateOnMount: false,
      cols: 'col-12 col-md-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
    },
    descritption: {
      label: computed(() => i18n.t('description')),
      name: 'description',
      as: 'textarea',
      type: '',
      rules: '',
      validateOnMount: 'false',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
    },
  })
  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('send'),
  }))

  return {
    formSchema,
    submitButton,
    submit,
    senderName,
    marketingItems,
    collapsedForm,
    addClickHandler: () => {
      collapsedForm.value = !collapsedForm.value
    },
    btnDisabled,
    deleteMarketingData: async(id) => {
      btnDisabled.value = true
      try {
        await deleteMarketingData(senderName.value.sender_name_id, id)
        refreshMarketingData()
        openModal(i18n.t('success'), i18n.t('forms.marketingData.deleteSuccess'))
      } catch (exception) {
        openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.marketingData.errors.', exception.response))
      }
      btnDisabled.value = false
    },
  }
}
