<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('senderNameDetails') }}</h3>
  </div>

  <modal-wrapper v-if="senderName" ref="modalFormHttpLinkWrapper">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('formHttpLink.addRedirectTitle') }}</h5>
          <button
            @click="closeHttpLinkModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <sender-name-http-link-form :init-values="{ http_link_redirect: senderName.http_link_redirect }" @send-form="sendHttpLinkForm"></sender-name-http-link-form>
        </div>
      </div>
    </div>
  </modal-wrapper>

  <modal-wrapper v-if="senderName" ref="modalIntegrationSettings">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('integrations.form.credentials.title') }}</h5>
          <button
            @click="closeCredentialsModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <DynamicForm
            :schema="integrationFormSchema"
            :initial-field-value="integrationFormInitialData"
            :button-data="integrationFormButton"
            :is-disabled="isIntegrationFormLoading"
            form-class="justify-content-start"
            @submit="integrationFormSendHandler"
          >
          </DynamicForm>
        </div>
      </div>
    </div>
  </modal-wrapper>

  <spinner-component v-if="!senderName" item-class="spinner-layout-gray"></spinner-component>
  <div v-if="senderName">
    <div class="task-detail-right">
      <div class="card">
        <div class="card-header">
          <h5>{{ $t('details') }}</h5>
        </div>
        <div class="card-body px-4 py-4">
          <table class="table m-0">
            <tbody>
              <tr>
                <td><i class="feather icon-user m-r-5"></i> {{ $t('senderName') }}</td>
                <td class="text-right">{{ senderName.sender_name }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('forms.senderName.status') }}
                </td>
                <td class="text-right">
                  {{ getLocalizedOrValueCurried('forms.senderName.statuses.' + senderName.status, senderName.status) }}
                  <span v-if="senderName.status === 'pending'" class="font-weight-bold"><br>{{ $t(`forms.senderName.pendingDescription`) }}</span>
                </td>
              </tr>
              <tr v-if="senderName.status === 'suspended'">
                <td>
                  <i class="feather icon-info m-r-5"></i>
                  <span class="font-weight-bold">
                    {{ $t(`forms.senderName.suspendedDescription`) }}
                  </span>
                </td>
                <td></td>
              </tr>
              <tr v-if="senderName.status === 'active' || senderName.status === 'suspended'">
                <td>{{ $t('deepLink') }}</td>
                <td class="middle-align text-right">
                  <p class="mb-0 break-word-wrapper">
                    <copy-button :text="senderName.imessage_link" />
                    {{ senderName.imessage_link }}
                    <a :href="senderName.imessage_send_msg_link" target="_blank" class="btn btn-primary btn-sm m-l-10">{{ $t('composeMessage') }}</a>
                  </p>
                </td>
              </tr>
              <tr v-if="senderName.status === 'active' || senderName.status === 'suspended'">
                <td>{{ $t('httpLink') }}</td>
                <td class="middle-align text-right">
                  <p class="mb-0 break-word-wrapper">
                    <copy-button :text="senderName.http_link" />
                    {{ senderName.http_link }}
                    <a href="#" @click.prevent="openHttpLinkModal" target="_blank" class="btn btn-primary btn-sm m-l-10">{{ $t('editRedirect') }}</a>
                  </p>
                </td>
              </tr>
              <tr v-if="senderName.status === 'active' || senderName.status === 'suspended'">
                <td>{{ $t('cancelSenderName') }}</td>
                <td class="text-right">
                  <modal-wrapper>
                    <template v-slot="{ close }">
                      <confirmation-modal
                        :close="close"
                        :content="$t('confirmCancelSenderNameHandler')"
                        @confirm="cancelSenderNameHandler"
                      ></confirmation-modal>
                    </template>
                    <template v-slot:trigger="{ open }">
                      <button
                        @click.prevent="open"
                        class="btn btn-sm btn-danger"
                      >{{ $t('cancel') }}</button>
                    </template>
                  </modal-wrapper>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5>{{ $t('webhooks') }}</h5>
        </div>
        <div v-if="canEditWebhooks" class="card-body px-4 py-4">
          <webhook-updating-form
            url-field-name="webhook_url"
            header-field-name="webhook_header"
            :is-disabled="isSenderWebhookDisabled"
            :license="license"
            :handler="submitSenderWebhook"
            :initial="{webhook_url: senderName.webhook_url, webhook_header: senderName.webhook_header}"
          ></webhook-updating-form>
        </div>
      </div>

      <div class="card" v-if="senderName.alias_list && senderName.alias_list.length > 0">
        <div class="card-header">
          <h5>{{ $t('forms.senderName.additionalServices') }}</h5>
        </div>
        <div
          v-for="(alias, index) in senderName.alias_list"
          :key="index"
          class="card-body px-4 py-4"
        >
          <table class="table">
            <tbody>
            <tr v-if="alias.contact">
              <td><b>{{ alias.contact }}</b></td>
            </tr>
            <tr v-else>
              <td><b>{{ $t('forms.senderName.pendingPhoneNumberDescription') }}</b></td>
            </tr>
            <tr>
              <td>{{ $t(`status`) }}</td>
              <td class="middle-align text-right">
                <p class="mb-0 break-word-wrapper">
                  {{ getLocalizedOrValueCurried('forms.senderName.statuses.' + alias.status, alias.status) }}
                </p>
              </td>
            </tr>
            <tr v-if="alias.forwarding_to_phone_number">
              <td>{{ $t('forms.senderName.forwardingToPhoneNumber') }}</td>
              <td class="middle-align text-right">
                <p class="mb-0 break-word-wrapper">
                  {{ alias.forwarding_to_phone_number }}
                </p>
              </td>
            </tr>
            <tr
              v-for="(modifier, index) in alias.modifier_list"
              :key="index"
            >
              <td>{{ modifier.description }}</td>
              <td class="text-right" v-if="modifier.type === 1">
                <modal-wrapper>
                  <template v-slot="{ close }">
                    <confirmation-modal
                      :close="close"
                      :content="$t('confirmCancelPhoneNumberHandler')"
                      @confirm="cancelSenderAliasHandler(modifier.id)"
                    ></confirmation-modal>
                  </template>
                  <template v-slot:trigger="{ open }" v-if="alias.status === 'active'">
                    <button
                      @click.prevent="open"
                      class="btn btn-sm btn-danger"
                    >{{ $t('cancel') }}</button>
                  </template>
                </modal-wrapper>
              </td>
              <td class="text-right" v-if="modifier.type === 2">
                <modal-wrapper>
                  <template v-slot="{ close }">
                    <confirmation-modal
                      :close="close"
                      :content="$t('confirmCancelServiceHandler')"
                      @confirm="cancelSenderAliasHandler(modifier.id)"
                    ></confirmation-modal>
                  </template>
                  <template v-slot:trigger="{ open }"  v-if="alias.status === 'active'">
                    <button
                      @click.prevent="open"
                      class="btn btn-sm btn-danger"
                    >{{ $t('cancel') }}</button>
                  </template>
                </modal-wrapper>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="table-separator mb-3"></div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5>{{ $t('forms.senderName.representativeSection') }}</h5>
        </div>
        <div class="card-body px-4 py-4">
          <table class="table">
            <tbody>
            <tr v-if="senderName.first_name">
              <td>{{$t('forms.checkout.name')}}</td>
              <td class="text-right">{{senderName.first_name}}</td>
            </tr>
            <tr v-if="senderName.last_name">
              <td>{{$t('forms.checkout.last_name')}}</td>
              <td class="text-right">{{senderName.last_name}}</td>
            </tr>
            <tr v-if="senderName.email">
              <td>{{$t('email')}}</td>
              <td class="text-right">{{senderName.email}}</td>
            </tr>
            <tr v-if="senderName.phone_number">
              <td>{{$t('phoneNumber')}}</td>
              <td class="text-right">{{senderName.phone_number}}</td>
            </tr>
            <tr v-if="senderName.company">
              <td>{{$t('companyName')}}</td>
              <td class="text-right">{{senderName.company}}</td>
            </tr>
            <tr v-if="senderName.form">
              <td>{{$t('forms.senderName.contactUrl')}}</td>
              <td class="text-right">{{senderName.form}}</td>
            </tr>
            </tbody>
          </table>
          <div class="table-separator mb-3"></div>
          <sender-contact-change-link />
        </div>
      </div>

      <div class="card" v-if="senderName.integrations">
        <div class="card-header">
          <h5>{{ $t('forms.senderName.integrationSection') }}</h5>
        </div>
        <div
          v-for="(integration, index) in senderName.integrations"
          :key="index"
          class="card-body px-4 py-4"
        >
          <h3 class="align-middle"><img :src="integration.icon" class="mr-2" style="height: 32px" />{{ integration.name }}</h3>
          <p>{{ $t(`integrations.${integration.vendor}.description`) }}</p>
          <table class="table">
            <tbody>
            <tr>
              <td class="align-middle">
                {{ $t('status') }}:
                <span v-if="integration.is_connected" class="text-c-green mb-0">
                    {{ $t('integrations.connectedTitle') }}
                  </span>
                <span v-if="!integration.is_connected" class="mb-0 text-muted">
                    {{ $t('integrations.disconnectedTitle') }}
                  </span>
              </td>
              <td class="text-right">
                <button
                  v-if="integration.is_connected && !integration.connect_button"
                  @click.prevent="() => openIntegrationFormModal(integration.fields, integration.vendor)"
                  class="btn btn-primary btn-sm"
                  :is-disabled="isIntegrationFormLoading"
                >
                  <i class="feather icon-sliders mx-1"></i>
                  {{ $t('settings') }}
                </button>
              </td>
            </tr>
            <tr class="text-right">
              <td></td>
              <td class="align-middle">

                <!-- Integration with button -->
                <div v-if="integration.connect_button && !integration.is_connected">
                  <a target="_blank" :href="integration.connect_button" class="btn btn-primary btn-sm">{{ $t('connect') }}</a>
                </div>

                <!-- Integration with fields and modal popup -->
                <div v-if="!integration.connect_button && !integration.is_connected">
                  <button class="btn btn-primary btn-sm" @click.prevent="() => openIntegrationFormModal(integration.fields, integration.vendor)">{{ $t('connect') }}</button>
                </div>

                <!-- Integration connected -->
                <div v-if="integration.is_connected">
                  <modal-wrapper>
                      <template v-slot="{ close }">
                        <confirmation-modal
                          :close="close"
                          :content="$t('integrations.deleteConfirmation')"
                          @confirm="() => removeIntegrationConnectionHandler(integration.vendor)"
                        ></confirmation-modal>
                      </template>
                      <template v-slot:trigger="{ open }">
                        <button
                          v-if="integration.is_connected"
                          @click.prevent="open"
                          class="btn btn-danger btn-sm"
                          :is-disabled="isIntegrationFormLoading"
                        >
                          <i class="feather icon-trash mx-1"></i>
                          {{ $t('integrations.removeTitle') }}
                        </button>
                      </template>
                    </modal-wrapper>
                  </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="table-separator mb-3"></div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h5>{{ $t('vcard.title') }}</h5>
        </div>
        <div v-if="!showVcardForm" class="card-body px-4 py-4">
          <table class="table">
            <tbody>
              <tr>
                <td>{{ $t('vcard.titleAction') }}</td>
                <td class="text-right">
                  <button v-if="!editVcardMode" class="btn btn-primary btn-sm mt-2 mr-2" @click.prevent="showVcardFormToggle"><i class="feather icon-file-plus mx-1"></i>{{ $t('vcard.addButton') }}</button>
                  <button v-if="editVcardMode" class="btn btn-primary btn-sm mt-2 mr-2" @click.prevent="showVcardFormToggle"><i class="feather icon-edit-2 mx-1"></i>{{ $t('vcard.editButton') }}</button>
                  <button v-if="editVcardMode" class="btn btn-primary btn-sm mt-2" @click.prevent="onDownloadVcardHandler"><i class="feather icon-download mx-1"></i>{{ $t('vcard.downloadButton') }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="showVcardForm" class="card-body px-4 py-4">
          <DynamicForm
            :schema="vCardFormScheme"
            :initial-values="vCardInitialData"
            :button-data="vCardSubmitButton"
            form-class="justify-content-start"
            :onChangeHandler="onChandeValueHandler"
            :is-disabled="isVcardButtonDisabled"
            @submit="submitVcardHandler"
          >
          </DynamicForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicForm from '../forms/DynamicForm.vue'
import { useModal } from '@/application/composables/modal'
import { defineAsyncComponent, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { getLocalizedOrValue, getLocalizedServerError } from '@/application/utils/localization'
import { isExactVersion, VERSION_0 } from '@/application/utils/versions'
import { useMarketingDataCreate } from '@/application/composables/marketingDataItemCreate'
import { useHumanDate } from '@/application/composables/humanDate'
import { useWebhookSenderUpdating } from '@/application/composables/webhookSenderUpdating.js'
import { deleteSenderAlias, deleteSenderName } from '@/application/services/sender-name'
import { useIntegrationForm } from '@/application/composables/integrationsForm.js'
import { useSenderNameHttpLink } from '@/application/composables/senderNameHttpLink.js'
import { useVcard } from '@/application/composables/useVcard.js'
import { getVcardDataRequest } from '@/application/services/vcard.js'

export default {
  name: 'DetailsPage',
  components: {
    DynamicForm,
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
    'webhook-updating-form': defineAsyncComponent(() => import('@/application/components/forms/WebhookUpdatingForm.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    'sender-contact-change-link': defineAsyncComponent(() => import('@/application/components/elements/links/SenderContactInfoChangeLink')),
    'copy-button': defineAsyncComponent(() => import('@/application/components/elements/CopyButton.vue')),
    'sender-name-http-link-form': defineAsyncComponent(() => import('@/application/components/pages/forms/SenderNameHttpLinkForm.vue')),
  },
  setup() {
    const i18n = useI18n()
    const { humanDateTime } = useHumanDate()
    const store = useStore()
    const license = computed(() => store.getters.getLicense)
    const isFirstVersion = computed(() => isExactVersion(license.value && license.value.version, VERSION_0))
    const router = useRouter()
    const { openModal } = useModal()
    const vueRouter = useRouter()
    const route = vueRouter.currentRoute.value
    const senderNameUuid = route.params.id

    const {
      formSchema: vCardFormScheme,
      submitButton: vCardSubmitButton,
      onChandeValueHandler,
      isVcardButtonDisabled,
      submitVcardHandler,
      vCardInitialData,
      updateVcardFormData,
      showVcardForm,
      showVcardFormToggle,
      editVcardModeToggle,
      editVcardMode,
      downloadVcardHandler,
    } = useVcard(senderNameUuid)

    const {
      modalFormHttpLinkWrapper,
      closeHttpLinkModal,
      openHttpLinkModal,
      submitHttpLinkForm,
    } = useSenderNameHttpLink()

    const {
      formSchema,
      submitButton,
      senderName,
      submit,
      btnDisabled,
    } = useMarketingDataCreate()

    const serverNameId = computed(() => senderName.value.sender_name_id)
    const {
      openIntegrationFormModal,
      modalIntegrationSettings,
      integrationFormSchema,
      closeCredentialsModal,
      integrationFormButton,
      isIntegrationFormLoading,
      integrationFormSendHandler,
      removeIntegrationConnectionHandler,
      integrationFormInitialData,
    } = useIntegrationForm(serverNameId)

    const {
      submitSenderWebhook,
      isSenderWebhookDisabled,
    } = useWebhookSenderUpdating(senderName)

    onMounted(() => {
      getVcardDataRequest(senderNameUuid).then(({ data }) => {
        updateVcardFormData(data.vcard)
        if (data.vcard) {
          editVcardModeToggle(true)
        }
      }).catch(err => {
        console.log('err: ', err)
      })
    })

    return {
      removeIntegrationConnectionHandler,
      integrationFormInitialData,
      modalIntegrationSettings,
      integrationFormButton,
      closeCredentialsModal,
      openIntegrationFormModal,
      integrationFormSchema,
      isIntegrationFormLoading,
      integrationFormSendHandler,
      senderName,
      getLocalizedOrValueCurried: getLocalizedOrValue.bind(null, i18n),
      formSchema,
      submit,
      submitButton,
      humanDateTime,
      btnDisabled,
      canEditWebhooks: computed(() => senderName.value),
      submitSenderWebhook,
      isSenderWebhookDisabled,
      license,
      isFirstVersion,
      cancelSenderNameHandler: async() => {
        try {
          await deleteSenderName(senderName.value.sender_name_id)
          router.push({ name: 'show-sender-names' })
        } catch (exception) {
          const { response } = exception
          if (response) {
            openModal(
              i18n.t('error'),
              getLocalizedServerError(i18n, 'forms.senderName.errors.', response)
            )
          }
        }
      },
      cancelSenderAliasHandler: async(id) => {
        try {
          await deleteSenderAlias(id)
          router.push({ name: 'show-sender-names' })
        } catch (exception) {
          const { response } = exception
          if (response) {
            openModal(
              i18n.t('error'),
              getLocalizedServerError(i18n, 'forms.senderName.errors.', response)
            )
          }
        }
      },
      modalFormHttpLinkWrapper,
      closeHttpLinkModal,
      openHttpLinkModal,
      sendHttpLinkForm: (data) => {
        submitHttpLinkForm(senderName.value.sender_name_id, data)
      },
      vCardFormScheme,
      vCardSubmitButton,
      onChandeValueHandler,
      isVcardButtonDisabled,
      submitVcardHandler,
      vCardInitialData,
      showVcardForm,
      showVcardFormToggle,
      editVcardMode,
      onDownloadVcardHandler: () => {
        downloadVcardHandler(senderName.value.imessage_link.replace('imessage://', ''))
      },
    }
  },
}
</script>
