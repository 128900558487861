import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal.js'
import { updateSenderNameWebhook } from '@/application/services/sender-name.js'

export const useWebhookSenderUpdating = (senderName) => {
  const { openModal } = useModal()
  const i18n = useI18n()
  const isSenderWebhookDisabled = ref(false)

  const submitSenderWebhook = (data) => {
    isSenderWebhookDisabled.value = true
    updateSenderNameWebhook(senderName.value.sender_name_id, data)
      .then(() => {
        openModal(i18n.t('success'), i18n.t('successSaving'))
        isSenderWebhookDisabled.value = false
      })
      .catch(err => {
        console.log(err)
        isSenderWebhookDisabled.value = false
      })
  }

  return {
    submitSenderWebhook,
    isSenderWebhookDisabled,
  }
}
