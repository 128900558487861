import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import vCardsJS from 'vcards-js'
import { getCountriesList } from '@/application/utils/countries'
import { saveVcardDataRequest } from '@/application/services/vcard'
import { useModal } from '@/application/composables/modal.js'

export function useVcard(senderNameUuid) {
  const i18n = useI18n()
  const { openModal } = useModal()
  const isVcardButtonDisabled = ref(false)
  const isSelectedOrganization = ref(null)
  const isSelectedAddress = ref(null)
  const vCardInitialData = ref({})
  const showVcardForm = ref(false)
  const editVcardMode = ref(false)

  const downloadVcardHandler = (senderNameEmail) => {
    const vCard = vCardsJS()

    if (vCardInitialData.value.is_organization) {
      vCard.organization = vCardInitialData.value.organization_name
      vCard.isOrganization = true
    } else {
      vCard.isOrganization = false
      vCard.firstName = vCardInitialData.value.first_name
      if (vCardInitialData.value.last_name) {
        vCard.lastName = vCardInitialData.value.last_name
      }
      if (vCardInitialData.value.middle_name) {
        vCard.middleName = vCardInitialData.value.middle_name
      }
    }
    if (vCardInitialData.value.photo_base64) {
      if (vCardInitialData.value.photo_url.endsWith('.png')) {
        vCard.photo.embedFromString(vCardInitialData.value.photo_base64, 'PNG')
      } else if (vCardInitialData.value.photo_url.endsWith('.gif')) {
        vCard.photo.embedFromString(vCardInitialData.value.photo_base64, 'GIF')
      } else {
        vCard.photo.embedFromString(vCardInitialData.value.photo_base64, 'JPEG')
      }
    }
    if (vCardInitialData.value.website_url) {
      vCard.url = vCardInitialData.value.website_url
    }
    if (vCardInitialData.value.email) {
      vCard.email = [vCardInitialData.value.email, senderNameEmail]
    } else {
      vCard.email = senderNameEmail
    }
    if (vCardInitialData.value.phone_number) {
      vCard.workPhone = vCardInitialData.value.phone_number
    }
    if (vCardInitialData.value.birthday) {
      vCard.birthday = new Date(vCardInitialData.value.birthday)
    }
    if (vCardInitialData.value.note) {
      vCard.note = vCardInitialData.value.note
    }
    // Address area
    if (vCardInitialData.value.need_add_address) {
      if (vCardInitialData.value.address_name) {
        vCard.workAddress.label = vCardInitialData.value.address_name
      }
      if (vCardInitialData.value.street) {
        vCard.workAddress.street = vCardInitialData.value.street
      }
      if (vCardInitialData.value.city) {
        vCard.workAddress.city = vCardInitialData.value.city
      }
      if (vCardInitialData.value.state) {
        vCard.workAddress.stateProvince = vCardInitialData.value.state
      }
      if (vCardInitialData.value.postal_code) {
        vCard.workAddress.postalCode = vCardInitialData.value.postal_code
      }
      if (vCardInitialData.value.country) {
        vCard.workAddress.countryRegion = vCardInitialData.value.country
      }
    }
    // Set social links
    if (vCardInitialData.value.facebook_url) {
      vCard.socialUrls.facebook = vCardInitialData.value.facebook_url
    }
    if (vCardInitialData.value.twitter_url) {
      vCard.socialUrls.twitter = vCardInitialData.value.twitter_url
    }
    if (vCardInitialData.value.linkedin_url) {
      vCard.socialUrls.linkedIn = vCardInitialData.value.linkedin_url
    }

    const filename = vCardInitialData.value.filename || 'vcard'

    var blob = new Blob([vCard.getFormattedString()], { type: 'text/vcard;charset=utf-8;' })
    var link = document.createElement('a')
    var url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `${filename}.vcf`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const showVcardFormToggle = () => {
    showVcardForm.value = !showVcardForm.value
  }

  const editVcardModeToggle = (newState) => {
    editVcardMode.value = newState
  }

  const updateVcardFormData = (data) => {
    vCardInitialData.value = data
    isSelectedOrganization.value = data.is_organization
    isSelectedAddress.value = data.need_add_address
  }

  const onChandeValueHandler = (fieldName, data) => {
    if (fieldName === 'is_organization') {
      isSelectedOrganization.value = data[fieldName]
    } else if (fieldName === 'need_add_address') {
      isSelectedAddress.value = data[fieldName]
    }
  }

  const formSchema = computed(() => ({
    is_organization: {
      label: i18n.t('vcard.forms.isOrganization'),
      name: 'is_organization',
      as: 'input',
      type: 'checkbox',
      rules: '',
      cols: ' col-12',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary d-inline',
    },
    organization_name: {
      label: i18n.t('vcard.forms.organizationName'),
      name: 'organization_name',
      as: 'input',
      type: 'text',
      rules: `${isSelectedOrganization.value ? 'required' : ''}`,
      cols: `col-12 ${isSelectedOrganization.value ? '' : 'hide-form-column'}`,
      inputClass: 'form-control',
    },
    first_name: {
      label: i18n.t('vcard.forms.firstName'),
      name: 'first_name',
      as: 'input',
      type: 'text',
      rules: `${isSelectedOrganization.value ? '' : 'required'}`,
      cols: `col-4 col-sm-6 ${isSelectedOrganization.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
    },
    last_name: {
      label: i18n.t('vcard.forms.lastName'),
      name: 'last_name',
      as: 'input',
      type: 'text',
      rules: '',
      cols: `col-4 col-sm-6 ${isSelectedOrganization.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
    },
    middle_name: {
      label: i18n.t('vcard.forms.middleName'),
      name: 'middle_name',
      as: 'input',
      type: 'text',
      rules: '',
      cols: `col-4 col-sm-6 ${isSelectedOrganization.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
    },
    photo_url: {
      label: i18n.t('vcard.forms.photoUrl'),
      name: 'photo_url',
      as: 'input',
      type: 'text',
      rules: 'media_url',
      cols: 'col-6 col-sm-6',
      inputClass: 'form-control',
      description: i18n.t('vcard.forms.photoDescription'),
    },
    website_url: {
      label: i18n.t('vcard.forms.websiteUrl'),
      name: 'website_url',
      as: 'input',
      type: 'text',
      rules: 'url',
      cols: 'col-6 col-sm-6',
      inputClass: 'form-control',
    },
    email: {
      label: i18n.t('vcard.forms.email'),
      name: 'email',
      as: 'input',
      type: 'text',
      rules: 'email',
      cols: 'col-6 col-sm-6',
      inputClass: 'form-control',
    },
    phone_number: {
      label: i18n.t('vcard.forms.phoneNumber'),
      name: 'phone_number',
      as: 'input',
      type: 'text',
      rules: '',
      cols: 'col-6 col-sm-6',
      inputClass: 'form-control',
    },
    birthday: {
      label: i18n.t('vcard.forms.birthday'),
      name: 'birthday',
      as: 'app-date-picker',
      type: 'hidden',
      rules: '',
      cols: 'col-6 col-sm-6',
      inputClass: 'form-control hidden-form-input',
    },
    note: {
      label: i18n.t('vcard.forms.note'),
      name: 'note',
      as: 'textarea',
      type: 'text',
      rules: '',
      cols: 'col-12',
      inputClass: 'form-control',
      description: i18n.t('vcard.forms.noteDescription'),
    },
    addressBlock: {
      cols: 'col-12',
      customHtml: '&nbsp;<div class="table-separator mb-3"></div>',
    },
    need_add_address: {
      label: i18n.t('vcard.forms.needAddAddress'),
      name: 'need_add_address',
      as: 'input',
      type: 'checkbox',
      rules: '',
      cols: ' col-12',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary d-inline',
    },
    address_name: {
      label: i18n.t('vcard.forms.addressName'),
      name: 'address_name',
      as: 'input',
      type: 'text',
      rules: '',
      cols: `col-12 col-sm-6 ${!isSelectedAddress.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
    },
    street: {
      label: i18n.t('vcard.forms.street'),
      name: 'street',
      as: 'input',
      type: 'text',
      rules: '',
      cols: `col-12 col-sm-6 ${!isSelectedAddress.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
    },
    city: {
      label: i18n.t('vcard.forms.city'),
      name: 'city',
      as: 'input',
      type: 'text',
      rules: '',
      cols: `col-12 col-sm-6 ${!isSelectedAddress.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
    },
    state: {
      label: i18n.t('vcard.forms.state'),
      name: 'state',
      as: 'input',
      type: 'text',
      rules: '',
      cols: `col-12 col-sm-6 ${!isSelectedAddress.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
    },
    postal_code: {
      label: i18n.t('vcard.forms.postalCode'),
      name: 'postal_code',
      as: 'input',
      type: 'text',
      rules: '',
      cols: `col-12 col-sm-6 ${!isSelectedAddress.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
    },
    country: {
      label: i18n.t('vcard.forms.country'),
      name: 'country',
      type: 'text',
      as: 'select',
      rules: '',
      cols: `col-12 col-sm-6 ${!isSelectedAddress.value ? 'hide-form-column' : ''}`,
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      children: getCountriesList().map(country => ({
        tag: 'option',
        value: country.code,
        text: country.name,
      })),
    },
    socialBlock: {
      cols: 'col-12',
      customHtml: '&nbsp;<div class="table-separator mb-3"></div>',
    },
    facebook_url: {
      label: i18n.t('vcard.forms.facebookUrl'),
      name: 'facebook_url',
      as: 'input',
      type: 'text',
      rules: 'url',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
    },
    twitter_url: {
      label: i18n.t('vcard.forms.twitterUrl'),
      name: 'twitter_url',
      as: 'input',
      type: 'text',
      rules: 'url',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
    },
    linkedin_url: {
      label: i18n.t('vcard.forms.linkedinUrl'),
      name: 'linkedin_url',
      as: 'input',
      type: 'text',
      rules: 'url',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
    },
    filenameBlock: {
      cols: 'col-12',
      customHtml: '&nbsp;<div class="table-separator mb-3"></div>',
    },
    filename: {
      label: i18n.t('vcard.forms.filename'),
      name: 'filename',
      as: 'input',
      type: 'text',
      rules: '',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
    },
  }))
  const submitButton = computed(() => ({
    wrapper: 'juatify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('save'),
  }))
  const submitVcardHandler = (data) => {
    isVcardButtonDisabled.value = true
    saveVcardDataRequest(senderNameUuid, data)
      .then(({ data }) => {
        isVcardButtonDisabled.value = false
        showVcardFormToggle()
        updateVcardFormData(data.vcard)
        openModal(i18n.t('success'), i18n.t('successSaving'), () => {
          window.location.reload()
        })
      })
      .catch(err => {
        console.log('Error: ', err)
        isVcardButtonDisabled.value = false
      })
  }
  return {
    formSchema,
    submitButton,
    isVcardButtonDisabled,
    onChandeValueHandler,
    submitVcardHandler,
    vCardInitialData,
    updateVcardFormData,
    showVcardForm,
    showVcardFormToggle,
    editVcardMode,
    editVcardModeToggle,
    downloadVcardHandler,
  }
}
