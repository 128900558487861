import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal'
import { sendHttpLinkRequest } from '@/application/services/httpLink.js'
import { getLocalizedServerError } from '@/application/utils/localization'

export const useSenderNameHttpLink = () => {
  const i18n = useI18n()
  const { openModal } = useModal()
  const modalFormHttpLinkWrapper = ref(null)
  const isHttpLinkFormDisabled = ref(false)

  const formSchemaHttpLink = computed(() => [
    {
      label: i18n.t('formHttpLink.redirect'),
      name: 'http_link_redirect',
      rules: 'url_or_deeplink',
      as: 'input',
      type: 'text',
      cols: 'col-12',
      inputClass: 'form-control',
      description: i18n.t('editRedirectDescription'),
    },
  ])
  const httpLinkFormButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('save'),
  }))
  const submitHttpLinkForm = (id, data) => {
    isHttpLinkFormDisabled.value = true
    sendHttpLinkRequest(id, data)
      .then(({ data }) => {
        if (data.success) {
          modalFormHttpLinkWrapper.value.close()
          openModal(i18n.t('success'), i18n.t('successSaving'))
        }
        isHttpLinkFormDisabled.value = false
      })
      .catch(err => {
        const response = err.response
        openModal(
          i18n.t('error'),
          getLocalizedServerError(i18n, 'forms.senderName.errors.', response)
        )
        isHttpLinkFormDisabled.value = false
      })
  }

  return {
    formSchemaHttpLink,
    httpLinkFormButton,
    modalFormHttpLinkWrapper,
    closeHttpLinkModal: () => {
      modalFormHttpLinkWrapper.value.close()
    },
    openHttpLinkModal: () => {
      modalFormHttpLinkWrapper.value.open()
    },
    isHttpLinkFormDisabled,
    submitHttpLinkForm,
  }
}
